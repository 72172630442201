import _child_process from "child_process";
import _parse from "./lib/parse";
import _enoent from "./lib/enoent";
var exports = {};
const cp = _child_process;
const parse = _parse;
const enoent = _enoent;

function spawn(command, args, options) {
  // Parse the arguments
  const parsed = parse(command, args, options); // Spawn the child process

  const spawned = cp.spawn(parsed.command, parsed.args, parsed.options); // Hook into child process "exit" event to emit an error if the command
  // does not exists, see: https://github.com/IndigoUnited/node-cross-spawn/issues/16

  enoent.hookChildProcess(spawned, parsed);
  return spawned;
}

function spawnSync(command, args, options) {
  // Parse the arguments
  const parsed = parse(command, args, options); // Spawn the child process

  const result = cp.spawnSync(parsed.command, parsed.args, parsed.options); // Analyze if the command does not exist, see: https://github.com/IndigoUnited/node-cross-spawn/issues/16

  result.error = result.error || enoent.verifyENOENTSync(result.status, parsed);
  return result;
}

exports = spawn;
exports.spawn = spawn;
exports.sync = spawnSync;
exports._parse = parse;
exports._enoent = enoent;
export default exports;
export const sync = exports.sync;
const _spawn = exports.spawn,
      _parse2 = exports._parse,
      _enoent2 = exports._enoent;
export { _spawn as spawn, _parse2 as _parse, _enoent2 as _enoent };