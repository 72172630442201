var exports = {};
// See http://www.robvanderwoude.com/escapechars.php
const metaCharsRegExp = /([()\][%!^"`<>&|;, *?])/g;

function escapeCommand(arg) {
  // Escape meta chars
  arg = arg.replace(metaCharsRegExp, "^$1");
  return arg;
}

function escapeArgument(arg, doubleEscapeMetaChars) {
  // Convert to string
  arg = `${arg}`; // Algorithm below is based on https://qntm.org/cmd
  // Sequence of backslashes followed by a double quote:
  // double up all the backslashes and escape the double quote

  arg = arg.replace(/(\\*)"/g, "$1$1\\\""); // Sequence of backslashes followed by the end of the string
  // (which will become a double quote later):
  // double up all the backslashes

  arg = arg.replace(/(\\*)$/, "$1$1"); // All other backslashes occur literally
  // Quote the whole thing:

  arg = `"${arg}"`; // Escape meta chars

  arg = arg.replace(metaCharsRegExp, "^$1"); // Double escape meta chars if necessary

  if (doubleEscapeMetaChars) {
    arg = arg.replace(metaCharsRegExp, "^$1");
  }

  return arg;
}

exports.command = escapeCommand;
exports.argument = escapeArgument;
export default exports;