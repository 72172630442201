import _fs from "fs";
import _shebangCommand from "shebang-command";
import _buffer from "buffer";
var exports = {};
var Buffer = _buffer.Buffer;
const fs = _fs;
const shebangCommand = _shebangCommand;

function readShebang(command) {
  // Read the first 150 bytes from the file
  const size = 150;
  const buffer = Buffer.alloc(size);
  let fd;

  try {
    fd = fs.openSync(command, "r");
    fs.readSync(fd, buffer, 0, size, 0);
    fs.closeSync(fd);
  } catch (e) {}
  /* Empty */
  // Attempt to extract shebang (null is returned if not a shebang)


  return shebangCommand(buffer.toString());
}

exports = readShebang;
export default exports;